import React from 'react'
import ReactDOM from 'react-dom'

interface Props {
  id: string
  children: React.ReactNode
}

export const Portal = ({ id, children }: Props): any => {
  const modalRoot = document.getElementById(id)
  if (!modalRoot) return null

  return ReactDOM.createPortal(children, modalRoot)
}
